/* Career.css */

.job-listings-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1.5rem;
    margin-top: 2rem;
}

.job-card {
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    padding: 1.5rem;
    position: relative;
    overflow: hidden;
}

.job-card:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    transform: translateY(-4px);
}

.job-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #1d4ed8; /* Blue color */
    margin-bottom: 0.5rem;
}

.job-description {
    color: #4b5563; /* Gray color */
    margin-bottom: 1rem;
}

.job-location, .job-department {
    color: #374151; /* Dark Gray color */
    margin-bottom: 0.5rem;
}

.apply-button {
    display: inline-block;
    background-color: #1d4ed8; /* Blue color */
    color: #ffffff;
    padding: 0.75rem 1.5rem;
    border-radius: 8px;
    text-align: center;
    text-decoration: none;
    font-weight: 600;
    transition: background-color 0.3s ease;
}

.apply-button:hover {
    background-color: #2563eb; /* Darker Blue */
}
