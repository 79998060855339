body {
    background-color: #fff;
}

/*  ========================= ' LANDING SECTION ' starts here ====================  */
/* .landingPage {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #fff;
} */

/* .landingPage-main {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1270px;
    padding: 4rem 2rem;
} */

.landingPage-main .left {
    /* background-color: yellow; */
    width: 50%;
    padding-right: 3rem;
}

.landingPage-main .left .landing-desc {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.landingPage-main .left .landing-desc p:nth-child(1) {
    color: var(--main-dark-color);
    font-size: 1.2rem;
    font-weight: 600;
}

.landingPage-main .left .landing-desc p:nth-child(2) {
    font-size: 3rem;
    line-height: 1.2;
    font-weight: 800;
}

.landingPage-main .left .landing-desc p:nth-child(2) span {
    color: var(--main-dark-color);
}

.landingPage-main .right {
    /* background-color: yellow; */
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.landingPage-main .right .col1 .landingPage-main .right .col2 .landingPage-main .right .col3 {
    display: flex;
    flex-direction: column;
    width: 33% !important;
    /* width: 40rem; */
}

.landingPage-main .right .col2 .col1,
.landingPage-main .right .col2 .col2,
.landingPage-main .right .col2 .col3 {
    transform: translateY(-1rem);
}

.landingPage-main .right .col1 .col2 img {

    border-radius: 10px;
    margin: 0.8rem;
    margin-right: 0 !important;
    display: flex;
    justify-content: center;
    height: auto;
    width: 10rem;
    object-fit: cover;
}

.landingPage-main .right .col2 .col1 img {
    border-radius: 10px;
    margin: 0.8rem;
    display: flex;
    justify-content: center;
    height: auto;
    width: 10rem;
    object-fit: cover;
}

.landingPage-main .right .col2 .col2 img {
    border-radius: 10px;
    margin: 0.8rem;
    display: flex;
    justify-content: center;
    height: auto;
    width: 10rem;
    object-fit: cover;

}

.landingPage-main .right .col2 .col3 img {
    border-radius: 10px;
    margin: 0.8rem;
    display: flex;
    justify-content: center;
    height: auto;
    width: 10rem;
    object-fit: cover;
}

.landingPage-main .right .col3 .col1 img {
    border-radius: 10px;
    margin: 0.8rem;
    margin-left: 0 !important;
    display: flex;
    justify-content: center;
    height: auto;
    width: 13rem;
    object-fit: cover;

}

.landingPage-main .right .col3 .col2 img {
    border-radius: 10px;
    margin: 0.8rem;
    margin-left: 0 !important;
    display: flex;
    justify-content: center;
    height: auto;
    width: 10rem;
    object-fit: cover;
    transform: translateY(-3rem);
}


.start-btn {
    margin-top: 2rem;
    background: linear-gradient(to right, var(--main-dark-color), var(--main-light-color));
    box-shadow: 0 2px 5px rgba(36, 36, 36, 0.685);
    padding: 1.2rem 2rem;
    width: fit-content;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 1.2rem;
    cursor: pointer;
}

.start-btn:hover {
    transition: all 0.3s linear;
    transform: translateY(-0.3rem);
    box-shadow: 0 5px 10px rgba(36, 36, 36, 0.685);
}

.start-btn p {
    color: #fff !important;

}

.nav-link {
    text-decoration: none;
    border-right: none;
}

.col-icon-common {
    padding: 0.4rem 0.6rem;
    background: linear-gradient(to bottom, var(--main-dark-color), var(--main-light-color));
    border-radius: 6px;
    color: #fff;
    width: fit-content;

}

.col12-icon {
    transform: translate(3rem, -1.5rem);
    rotate: -15deg;
}

.col31-icon {
    transform: translate(2rem, -18rem);
}

.col32-icon {
    rotate: 15deg;
    transform: translate(4rem, -5.5rem);

}


@media (max-width: 1270px) {
    .landingPage-main {
        width: 100%;
    }
}


@media (max-width: 1080px) {
    .landingPage-main .right {
        display: none;
    }

    .landingPage-main {
        align-items: center;
        justify-content: center;
    }

    .landingPage-main .left {
        width: 590px;
        padding-right: 0;
    }

}


/*  ========================= ' LANDING SECTION ' ends here ====================  */




/*  ========================= ' COMPANY LOGO ' starts here ====================  */
.companylogo {
    width: 1100px;
}

.companylogo-main {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    gap: 3rem;
    padding: 3rem 2rem;
    background-color: #fff;
}

.companylogo-main img {
    height: 2.5rem;
    width: auto;
    opacity: 0.2;
}

.companylogo-main img:hover {
    transition: all 0.2s ease-in;
    opacity: 0.4;
}

@media (max-width: 1100px) {
    .companylogo {
        width: 100%;
    }

}

/*  ========================= ' COMPANY LOGO ' ends here ====================  */



/*  ========================= ' OUR SERVICE ' starts here ====================  */
.ourservice {
    width: 1270px;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.our-service-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    margin: auto;
    width: 100%;
    padding: 5rem 0;
    background-color: #fff;
}

.our-service-main .service-heading p {
    font-size: 3rem;
    margin-bottom: 2rem;
}

.service-desc {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    flex-wrap: wrap;
    justify-content: center;
}

.service-common {
    width: 18rem;
    box-shadow: 0 0 10px rgb(217, 217, 217);
    border-radius: 10px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: flex-start;
    align-items: flex-start;
}

.service-common .image {
    width: 100%;
    height: 5rem;
    display: flex;
    align-items: center;
}

.service-desc-4 .image img {
    height: 3rem;
    width: auto;
}

/* .service-desc-4 .image img , */
.service-desc-1 .image img,
.service-desc-2 .image img,
.service-desc-3 .image img,
.service-desc-5 .image img,
.service-desc-6 .image img,
.service-desc-7 .image img,
.service-desc-8 .image img {
    height: 5rem;
    width: auto;
}

.service-common .heading p {
    font-size: 1.2rem;
    font-weight: 600;
}

.service-desc .desc {
    color: #333;
}

@media (max-width: 1270px) {
    .ourservice {
        width: 100%;
    }

}

/*  ========================= ' OUR SERVICE ' ends here ====================  */







/*  ========================= ' WHY SHERISE ' ends here ====================  */

.whysherise {
    width: 1270px;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.whysherise-main {
    display: flex;
    flex-direction: row-reverse;
    padding: 5rem 2rem;
    background-color: #fff;
    /* align-items: center; */

}

.whysherise-main .heading p:nth-child(1) {
    color: rgb(160, 160, 160);
}

.whysherise-main .heading p:nth-child(2) {
    font-size: 3rem;
    color: #333;
}

.whysherise-main .left {
    width: 50%;
    /* padding: 0 2rem; */
    /* background-color: yellow; */
}

.whysherise-main .right {
    width: 50%;
}

.whysherise-main .right img {
    height: auto;
    width: 90%;
}

.all-reasons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.all-reasons .reason-common .heading p {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    color: var(--main-dark-color) !important;

}

.all-reasons .reason-common .desc p {
    color: #333;
}

@media (max-width: 1270px) {
    .whysherise {
        width: 100%;
    }

}

@media (max-width: 800px) {
    .whysherise-main {
        display: flex;
        flex-direction: column;

    }

    .whysherise-main .left {
        width: 100%;
        /* padding: 0 2rem; */
        /* background-color: yellow; */
    }

    .whysherise-main .right {
        display: none;
        width: 100%;
    }

}


/*  ========================= ' WHY SHERISE ' ends here ====================  */






/*  ========================= ' Mentor Says ' starts here ====================  */
.ourmentor {
    width: 1270px;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    margin: auto;
}

.ourmentor-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5rem 2rem;
}

.ourmentor-main .heading p {
    font-size: 3rem;
    margin-bottom: 1rem;
}

.mentor-quote {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}

.quote-desc {
    font-size: 0.9rem;
    margin-bottom: 1rem;
}

.mentor-quote .quote1 {
    width: 22rem;
    background-color: var(--main-opacity-color);
    /* box-shadow: 0 0 10px rgb(217, 217, 217); */
    border-radius: 10px;
    padding: 2rem;
    gap: 1rem !important;
}

.quote1 .image {
    margin-bottom: 1rem;
}

.quote1 .mentor-desc p {
    line-height: 1.3;
}

.quote1 .mentor-desc p:nth-child(1) {
    font-size: 0.9rem;
}

.quote1 .mentor-desc p:nth-child(2) {
    font-size: 0.8rem;
    font-style: italic;
    color: rgb(107, 107, 107);
}

.quote1 .image img {
    height: 5rem;
    width: auto;

}

@media (max-width: 1270px) {
    .ourmentor {
        width: 100%;
    }

}

/*  ========================= ' Mentor Says ' ends here ====================  */





/*  ========================= ' TESTIMONIAL SECTION ' starts here ====================  */


/*  ========================= ' TESTIMONIAL SECTION ' ends here ====================  */















/* .opportunity-carousel, .video-player, .why-she-works, .testimonial-carousel, .contact-landing {
    text-align: center;
    padding: 2rem 0;
} */









/*  ========================= ' CONTACT SECTION ' starts here ====================  */
/* 
.contact-landing {
    width: 100%;
    display: flex;
    justify-content: center ; 
    align-items: center ;
    
}
.contact-landing .contact-landing-main-div {
    width: 1300px;
    background-image:linear-gradient(#78d7c27e, #78d7c286), url("../../assets/images/contact-background.jpg");
    background-position: center;
    background-size: cover;
    padding: 1rem 2rem;
    border-radius: 5px;
    z-index: 3;
}
.description-contact-landing {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    z-index: 4;
}
.description-contact-landing .left {
    display: flex;
    flex-direction: column;
    color: #fff;
}
.description-contact-landing .left p {
    text-shadow: 0 0 5px rgb(112, 112, 112);
}
.description-contact-landing .right {
    display: flex;
    align-items: center;
    justify-content: center;
}
.description-contact-landing .right a {
    padding: 0.8rem 1.5rem;
    background-color: #fff;
    border-radius: 5px;
    margin: 1rem 0;
} */

/*  ========================= ' CONTACT SECTION ' ends here ====================  */